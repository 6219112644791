(function ($, prodcat, site) {
  var lastIndex = 0;

  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    var $product = $(this);
    var $imageContainer = $('.js-product-full__image', $product);

    if (!$imageContainer.length) {
      return;
    }
    var prod = $product ? prodcat.data.getProduct($product.data('product-id')) : null;
    var sku = prodcat.data.getSku(skuBaseId);

    // Populate the Sku data field LARGE_ALT_IMAGES with data from the product if it's empty
    if (sku.LARGE_ALT_IMAGES.length === 0) {
      sku.LARGE_ALT_IMAGES = prod.LARGE_ALT_IMAGES;
    }

    // Parse the SKU otherwise parse the defaultSku of the product or just the product image
    var data = sku ? sku : prod.defaultSku ? prod.defaultSku : prod;
    // @todo integrate video alts
    // check for video
    var $videoContainer = $('.js-spp-carousel__slide--video:not(.slick-cloned)', $imageContainer);
    var hasVideo = $videoContainer.length ? 1 : 0;

    if (hasVideo) {
      var $video = $('.js-product-video', $videoContainer);
      var yt = !!$video.attr('data-video-provider');
      var video_image = $('.js-video_image', $video).attr('data-src');
      var ytId = '';
      // Translate Alt videos
      var locale = '';
      var localeMatch = document.cookie.match(new RegExp('(?:^|; )LOCALE=([^;]*)'));

      if (localeMatch && localeMatch.length !== 0) {
        locale = localeMatch[1].toLowerCase().split('_')[0];
      }

      // Handle Alt videos
      if ($video.length >= 1) {
        for (var i = 0; i < $video.length; i++) {
          video_image = $('.js-video_image', $video[i]).attr('data-src');
          ytId = $video[i].attributes['data-youtube-id'].value;
          var alt_marker = '';

          if (i > 0) {
            alt_marker = '_alt_' + i;
          }
          var translationsString = $videoContainer[i].attributes['data-youtube-translations'].value;

          if (translationsString !== '') {
            try {
              var translationsData = JSON.parse(translationsString);

              if (translationsData[locale]) {
                ytId = translationsData[locale];
              }
            } catch (e) {
              // translationsString not a valid JSON string
            }
          }

          data = _.merge(data, {
            ['video_image' + alt_marker]: {
              src: video_image
            },
            ['video' + alt_marker]: {
              ['video_image' + alt_marker]: {
                src: video_image
              },
              'provider-youtube': yt,
              youtube_id: ytId
            }
          });
        }
      } else {
        ytId = $video.attr('data-youtube-id');

        data = _.merge(data, {
          video_image: {
            src: video_image
          },
          video: {
            video_image: {
              src: video_image
            },
            'provider-youtube': yt,
            youtube_id: ytId
          }
        });
      }

      // additional support will be needed for html5 and zentrick
      // provider-htmlfive
      // provider-zentrick
      // id
      // html
    }
    /* ------- Sku Images ------- */
    var product_image_full = site.template.get({
      name: 'product_image_full_v2',
      data: data
    });

    $imageContainer
      .fadeTo(100, 0, 'linear', function () {
        if ($(this).find('.slick-initialized').length) {
          lastIndex = $(this).find('.slick-initialized').slick('slickCurrentSlide');
        }
        $(this).html($(product_image_full).html());
        // reattach video
        if (hasVideo) {
          $(document).trigger('youtubeIframeAPI.loaded');
        }
        // Refresh the slick slider if there is one
        Drupal.behaviors.productImageFullV2.attach($product);
        $(document).trigger('spp-slick');
      })
      .fadeTo(100, 1, 'linear');
  });

  $(document).on('zoom.fadeIn', function () {
    var size = Unison.fetch.now().name;

    if (size === 'large') {
      $('.js-product-zoom').addClass('zoom--active');
    }
  });

  $(document).on('zoom.fadeOut', function () {
    var size = Unison.fetch.now().name;

    if (size === 'large') {
      $('.js-product-zoom').removeClass('zoom--active');
    }
  });

  Drupal.behaviors.productImageFullV2 = {
    attach: function (context) {
      // images
      var $containers = $('.product-full__image', context);
      var $carousel = $();
      var currentSlide = '';

      $containers.each(function () {
        var $container = $(this);
        var $sppImages = $('.product-full__image-carousel', $container);

        $carousel = $('.js-spp-carousel', $sppImages);

        var arrowsDiv = $('.carousel-controls', $container);
        var dotsDiv = $('.carousel-dots', $container);
        var sppSlide = '.js-spp-carousel__slide';
        // DAEURO-215 - Replacing empty image by placeholder image
        var productId = $('.js-product').data('product-id');
        var prod = prodcat.data.getProduct(productId);

        if (prod) {
          jQuery.each(prod.LARGE_ALT_IMAGES, function (index, value) {
            $.ajax({
              url: value,
              type: 'HEAD',
              error: function () {
                $('img[data-src="' + value + '"]')
                  .parent()
                  .remove();
                $(document).trigger('spp-slick.reset');
              }
            });
          });
        }
        // case for custom slides count and dots for mobile
        var settings = {
          slide: sppSlide,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
          appendArrows: arrowsDiv,
          appendDots: dotsDiv
        };
        var init_default_carousel = function () {
          // Init this carousel with our settings
          $carousel.not('.slick-initialized').slick(settings);
          $carousel.slick('slickFilter', ':not(.hidden)');

          if (lastIndex) {
            $carousel.slick('slickGoTo', lastIndex);
            lastIndex = 0;
          }
        };
        var refresh_carousel = function () {
          $carousel.slick('slickFilter', ':not(.hidden)');
          $carousel.slick('setPosition');
        };

        init_default_carousel();

        $carousel.on('refresh', refresh_carousel);

        var size = Unison.fetch.now().name;

        if (size === 'landscape') {
          var $imageZoom = $('.js-product-zoom', $container);

          $imageZoom.zoom({
            magnify: 2
          });
        }

        $(document).on('spp-slick.reset', function () {
          if (!$carousel) {
            return;
          }
        });
      });
      $(document).trigger('spp-slick');
    }
  };
})(jQuery, window.prodcat || {}, window.site || {});
